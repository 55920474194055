<template>
  <div>
    <h1 class="h2 indexPanel-title">重設密碼</h1>
    <Form class="form" @submit="onSubmit" :validation-schema="schema" v-if="!isFinish">
      <FormInput field-type="password" field-name="password" field-label="新密碼" />
      <FormInput field-type="password" field-name="confirmPassword" field-label="確認新密碼" />
      <div class="btn-group btn-group-vertical">
        <button class="btn btn-primary btn-full">送出</button>
      </div>
    </Form>
    <div v-else>
      <p>密碼重設成功，請用新密碼登入</p>
      <div class="btn-group btn-group-vertical">
        <RouterLink to="/login" class="btn btn-secondary btn-full">返回</RouterLink>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/api';
import { resetPassword as schema } from '@/utils/schema';
import FormInput from '@/components/Input/FormInput.vue';
import { Form } from 'vee-validate';

export default {
  components: {
    Form,
    FormInput,
  },
  data() {
    return {
      schema,
      tempToken: '',
      isFinish: false,
    };
  },
  methods: {
    async onSubmit(formData) {
      const isPassed = await api.forgotPasswordUpdatePassword({
        tempToken: this.tempToken,
        password: formData.password,
      });

      if (isPassed) {
        this.isFinish = true;
      } else {
        alert('發生錯誤密碼未變更成功，請聯絡系統工程師');
        this.directToLogin();
      }
    },
    directToLogin() {
      this.$router.push('/login');
    },
  },
  async created() {
    // 驗證是否 token 到期 (token 由網址挾帶)
    this.tempToken = this.$route.query.token;
    if (!this.tempToken) {
      alert('無token');
      this.directToLogin();
    } else {
      const isPassed = await api.forgotPasswordCodeCheck(this.tempToken);
      if (!isPassed) {
        alert('重設密碼連結已逾期');
        this.directToLogin();
      }
    }
  },
};
</script>
